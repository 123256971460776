// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-settings-tsx": () => import("./../../../src/pages/cookie-settings.tsx" /* webpackChunkName: "component---src-pages-cookie-settings-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-ventures-tsx": () => import("./../../../src/pages/ventures.tsx" /* webpackChunkName: "component---src-pages-ventures-tsx" */),
  "component---src-pages-verify-email-tsx": () => import("./../../../src/pages/verify-email.tsx" /* webpackChunkName: "component---src-pages-verify-email-tsx" */),
  "component---src-pages-webview-marketplace-tsx": () => import("./../../../src/pages/webview/marketplace.tsx" /* webpackChunkName: "component---src-pages-webview-marketplace-tsx" */),
  "component---src-pages-webview-news-tsx": () => import("./../../../src/pages/webview/news.tsx" /* webpackChunkName: "component---src-pages-webview-news-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/generic-page.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-legal-document-tsx": () => import("./../../../src/templates/legal-document.tsx" /* webpackChunkName: "component---src-templates-legal-document-tsx" */),
  "component---src-templates-marketplace-category-tsx": () => import("./../../../src/templates/marketplace/category.tsx" /* webpackChunkName: "component---src-templates-marketplace-category-tsx" */),
  "component---src-templates-marketplace-product-tsx": () => import("./../../../src/templates/marketplace/product.tsx" /* webpackChunkName: "component---src-templates-marketplace-product-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news/post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-webview-legal-document-tsx": () => import("./../../../src/templates/webview/legal-document.tsx" /* webpackChunkName: "component---src-templates-webview-legal-document-tsx" */)
}

